// import { useEffect, useState } from 'react';
// import Image from 'next/image';
import useWcentrixChat from '../../hooks/useWcentrixChat';
// import styles from './AsistantChat.module.scss';

const WebCentrix = () => {
   useWcentrixChat();
   return null;
};
// const Whatsapp = () => {
//    const clickAsistantChat = () => {
//       window.open('https://wa.me/5492614762339?text=Hola!');
//    };
//    return (
//       <button onClick={clickAsistantChat} className={styles.assistantChat}>
//          <div className={styles.containerLogo}>
//             <Image width="30px" height="30px" src="/assets/whatsApp.svg" alt="Whatsapp logo" />
//          </div>
//          <p>WhatsApp</p>
//       </button>
//    );
// };
function AsistantChat() {
   // const [whatsappAvailable, setWhatsappAvailable] = useState(null);

   // useEffect(() => {
   //    (() => {
   //       const weekday = [
   //          'sunday',
   //          'monday',
   //          'tuesday',
   //          'wednesday',
   //          'thursday',
   //          'friday',
   //          'saturday',
   //       ];

   //       const date = new Date();
   //       const day = weekday[date.getDay()];
   //       const hours = date.getHours();
   //       const minutes = date.getMinutes();

   //       if (day === 'saturday') {
   //          if (hours > 12 || (hours === 12 && minutes > 30)) {
   //             return setWhatsappAvailable(false);
   //          }
   //          return setWhatsappAvailable(true);
   //       }
   //       if (day === 'sunday') {
   //          return setWhatsappAvailable(false);
   //       }
   //       if (day === 'monday') {
   //          if (hours >= 8) {
   //             return setWhatsappAvailable(true);
   //          }
   //          return setWhatsappAvailable(false);
   //       }
   //       return setWhatsappAvailable(true);
   //    })();
   // }, []);
   // if (process.env.NEXT_PUBLIC_MAINTENANCE !== 'true') {
   //    if (process.env.NEXT_PUBLIC_STORE_ID !== 'vaypol') {
   //       return <WebCentrix />;
   //    }
   //    if (whatsappAvailable) {
   //       return <Whatsapp />;
   //    }
   //    return whatsappAvailable !== null ? <WebCentrix /> : <></>;
   // }
   // return <></>;
   return <WebCentrix />;
}
export default AsistantChat;

/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React from 'react';
import cn from 'classnames';
import Link from 'next/link';
import { useRouter } from 'next/router';
import { MdSearch } from 'react-icons/md';
import useTrackingQueue from '../../../../../../hooks/useTrackingQueue';
import generateHrefLink from '../../../../../../services/page/generateLinkHref';
import styles from '../suggestions-list.module.scss';

export default function SuggestionItem(props) {
   const { name, url, onClick, urls } = props;
   const { executeEvent } = useTrackingQueue();

   const handleClick = () => {
      // ejecutamos el evento "search" de Google Tag Manager
      const keyword = url.split('/');
      executeEvent(
         'search',
         `sug: ${
            (keyword[0] === 'adidas' && 'adidas') ||
            (keyword[1] === 'k' ? keyword[keyword.length - 1] : keyword[1])
         }`,
      );
      onClick();
   };

   const getUrls = () => (urls?.city ? urls?.city : urls?.vaypol);

   const styleTag = cn({
      [styles.tag]: true,
      [styles.tagCity]: urls?.city && !urls.vaypol,
      [styles.tagVaypol]: urls?.vaypol,
   });

   return (
      <Link href={generateHrefLink(url || getUrls(), useRouter())}>
         <a className={styles.item} onClick={handleClick}>
            <MdSearch />
            <span>{name}</span>
            <div className={styleTag}>
               {urls?.city && !urls.vaypol && 'City'}
               {urls?.vaypol && 'Vaypol'}
            </div>
         </a>
      </Link>
   );
}
